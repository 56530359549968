import { mapGetters } from 'vuex';
import { getOneLegsAirlines, getOneLegsAirports } from '@/utils/sabreUtils/common';

const tMinutesPerDay = 24 * 60;
const filterControl = {
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      directionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
      searchResult: 'GET_SABRE_FLIGHT_SEARCH_RESULT',
      airlineCompanies: 'GET_SABRE_AIRLINE_COMPANIES',
      airports: 'GET_SABRE_AIRPORTS',

      filterConditions: 'GET_SABRE_FILTER_CONDITIONS',
    }),
    isOneWay() {
      const { filterConditions } = this;
      return filterConditions?.isOneWay || false;
    },
    persons() {
      const { filterConditions } = this;
      return filterConditions?.persons || 2;
    },
    stopsFilterState() {
      const { filterConditions } = this;
      return filterConditions?.stopsFilterState || [];
    },
    durFilterValue1() {
      const { filterConditions } = this;
      return filterConditions?.durFilterValue1 || 100;
    },
    durFilterValue2() {
      const { filterConditions } = this;
      return filterConditions?.durFilterValue2 || 100;
    },
    selectedAirline() {
      const { filterConditions } = this;
      return filterConditions?.selectedAirline || [];
    },
    selectedAirport() {
      const { filterConditions } = this;
      return filterConditions?.selectedAirport || [];
    },
    departureTimeFilter() {
      const { filterConditions } = this;
      return filterConditions?.departureTimeFilter || [0, tMinutesPerDay];
    },
    arrivalTimeFilter() {
      const { filterConditions } = this;
      return filterConditions?.arrivalTimeFilter || [0, tMinutesPerDay];
    },
    budgetDuration() {
      const { filterConditions } = this;
      return filterConditions?.budgetDuration || [0, 10000];
    },
    filterDisabled() {
      const { filterConditions } = this;
      return filterConditions?.filterDisabled;
    },
  },
  watch: {
    searchResult() {
      if (!this.searchResult?.itineraries?.length) {
        this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'filterDisabled', value: true });
      }
    },
    $route() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'filterDisabled', value: true });
    },
  },
  methods: {
    applyFilter() {
      const resultList = this.searchResult?.itineraries || [];
      const { isOneWay, persons } = this;
      const { stopsFilterState, durFilterValue1, durFilterValue2, selectedAirline, selectedAirport, departureTimeFilter, arrivalTimeFilter, budgetDuration } = this;

      const filtered = resultList?.filter((item) => {
        // stops filter condition
        const connections0 = item.legs[0].excerpt.connections;
        const connections1 = item.legs[1]?.excerpt?.connections;
        const stopsCondition = stopsFilterState.includes(connections0) || stopsFilterState.includes(connections1);

        // duration filter condition
        const durationCondition1 = item.legs[0].excerpt.elapsedTime < durFilterValue1 * 60;
        const durationCondition2 = (isOneWay) ? true : item.legs[1].excerpt.elapsedTime < durFilterValue2 * 60;

        // airline filter condition
        const oneLegsAirlines = getOneLegsAirlines(item).flat().flat();
        const airlineCondition = oneLegsAirlines.some((one) => selectedAirline.includes(one));

        // airport filter condition
        const oneLegsAirports = getOneLegsAirports(item).flat().flat();
        const airportCondition = oneLegsAirports.some((one) => selectedAirport.includes(one));

        // time range filter condition -> when depart the departure time and when return the arrival time
        const departureMinute = this.getMinutes(item.legs[0].excerpt.departure.time);
        const departureCondition = departureMinute >= departureTimeFilter[0] && departureMinute < departureTimeFilter[1];
        const arrivalMinute = (isOneWay) ? true : this.getMinutes(item.legs[1].excerpt.departure.time);
        const arrivalCondition = (isOneWay) ? true : arrivalMinute >= arrivalTimeFilter[0] && arrivalMinute < arrivalTimeFilter[1];
        const timeRangeCondition = (isOneWay) ? departureCondition : departureCondition && arrivalCondition;

        // budget filter condition
        const price = Math.floor(item.excerpt.totalPrice / persons);
        const budgetCondition = price >= budgetDuration[0] && price <= budgetDuration[1];

        return stopsCondition && durationCondition1 && durationCondition2 && airlineCondition && airportCondition && timeRangeCondition && budgetCondition;
      }) || [];
      this.$store.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', filtered);
    },
    getMinutes(time) {
      const tArray = time.split(':');
      const hour = +tArray[0], minute = +tArray[1];
      return hour * 60 + minute;
    },
  },
};

export default filterControl;
