<template>
  <div id="time-range" class="rangedubleslider">
    <p>{{$t("sabre.search-result.between-hours")}}</p>
    <div class="sliders_step1">
      <div :ref="`range-slider-${_uid}`"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'range-slider',
  props: ['rangeValue', 'min', 'max', 'step', 'type'],
  model: {
    prop: 'rangeValue',
    event: 'change',
  },
  watch: {
    step() {
      this.jqueryObject.slider('option', 'step', this.step);
    },
    min() {
      this.jqueryObject.slider('option', 'min', this.min);
    },
    max() {
      this.jqueryObject.slider('option', 'max', this.max);
    },
    rangeValue() {
      this.jqueryObject.slider('option', 'values', this.rangeValue);
    },
  },
  data() {
    return {
      jqueryObject: null,
    };
  },
  mounted() {
    this.create();
  },
  methods: {
    create() {
      const vm = this;
      this.jqueryObject = $(this.$refs[`range-slider-${this._uid}`]);

      this.jqueryObject.slider({
        values: this.rangeValue,
        min: this.min,
        max: this.max,
        step: this.step,
        range: true,
        slide(event, ui) {
          const { values } = ui;
          vm.$emit('change', values);

          const diff = values[1] - values[0];
          const firstHandle = $(this).find('.ui-slider-handle:first');
          const secondHandle = $(this).find('.ui-slider-handle:last');

          const firstValue = (vm.type === 'budget') ? `<span>${vm.$t('sabre.search-result.min')} </span> $ ${values[0]}` : vm.getHourMinuteString(values[0]);
          const secondValue = (vm.type === 'budget') ? `<span>${vm.$t('sabre.search-result.max')}</span> $ ${values[1]}` : vm.getHourMinuteString(values[1]);

          firstHandle.html(`
          <div class="tooltip top slider-tip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner${diff < 280 ? ` align-start${diff < 80 ? ' more-left' : ''}` : ''}">${firstValue}</div>
          </div>`);
          secondHandle.html(`
          <div class="tooltip top slider-tip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner${diff < 280 ? ` align-end${diff < 80 ? ' more-right' : ''}` : ''}">${secondValue}</div>
          </div>`);
        },
        create() {
          const firstHandle = $(this).find('.ui-slider-handle:first');
          const secondHandle = $(this).find('.ui-slider-handle:last');

          const firstValue = (vm.type === 'budget') ? `<span>${vm.$t('sabre.search-result.min')} </span> $ ${vm.rangeValue[0]}` : vm.getHourMinuteString(vm.rangeValue[0]);
          const secondValue = (vm.type === 'budget') ? `<span>${vm.$t('sabre.search-result.max')}</span> $ ${vm.rangeValue[1]}` : vm.getHourMinuteString(vm.rangeValue[1]);

          firstHandle.html(`
          <div class="tooltip top slider-tip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner">${firstValue}</div>
          </div>`);
          secondHandle.html(`
          <div class="tooltip top slider-tip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner">${secondValue}</div>
          </div>`);
        },
        change() {
          const diff = vm.rangeValue[1] - vm.rangeValue[0];
          const firstHandle = $(this).find('.ui-slider-handle:first');
          const secondHandle = $(this).find('.ui-slider-handle:last');

          const firstValue = (vm.type === 'budget') ? `<span>${vm.$t('sabre.search-result.min')} </span> $ ${vm.rangeValue[0]}` : vm.getHourMinuteString(vm.rangeValue[0]);
          const secondValue = (vm.type === 'budget') ? `<span>${vm.$t('sabre.search-result.max')}</span> $ ${vm.rangeValue[1]}` : vm.getHourMinuteString(vm.rangeValue[1]);

          firstHandle.html(`
          <div class="tooltip top slider-tip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner${diff < 280 ? ` align-start${diff < 80 ? ' more-left' : ''}` : ''}">${firstValue}</div>
          </div>`);
          secondHandle.html(`
          <div class="tooltip top slider-tip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner${diff < 280 ? ` align-end${diff < 80 ? ' more-right' : ''}` : ''}">${secondValue}</div>
          </div>`);
        },
      });
    },
    getHourMinuteString(minuteValue) {
      const hours = Math.floor(minuteValue / 60);
      const minutes = minuteValue % 60;

      const hString = hours < 10 ? `0${hours >= 24 ? hours - 24 : hours}` : `${hours}`;
      const mString = minutes < 10 ? `0${minutes}` : `${minutes}`;

      return `${hString}:${mString}`;
    },
  },
};
</script>
