<template>
  <div class="btn-group hiddenclass" v-if="device==='desktop'">
    <button class="btn btn-outline-primary dropdown-toggle" type="button" :class="{'disabled' : filterDisabled}" ref="timeFilter"
     id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" @click="() => showedDropdown()">
      {{(isFiltered) ? $t("sabre.search-panel.filtered.go-between", {hour: Math.floor(departureFilter[1]/60)}) : $t("sabre.search-result.departure-times")}}
    </button>
    <div class="dropdown-menu dropdownranger" aria-labelledby="dropdownMenuClickableInside">

      <range-slider v-model="departureFilter" :min="0" :max="24 * 60" :step="15"/>
      <range-slider v-model="arrivalFilter" :min="0" :max="24 * 60" :step="15" v-if="!isOneWay"/>

      <div class="btn-group-area d-flex justify-content-center">
        <button class="cleanup_btn" @click="() => clean()">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>
  </div>

  <div class="accordion-item" v-else>
    <h2 class="accordion-header" id="heading4">
      <button class="accordion-button collapsed" type="button" :class="{'disabled' : filterDisabled}"
       data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
        {{$t("sabre.search-result.departure-times")}}
      </button>
    </h2>
    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
      <div class="accordion-body">

      <range-slider v-model="departureFilter" :min="0" :max="24 * 60" :step="15"/>
      <range-slider v-model="arrivalFilter" :min="0" :max="24 * 60" :step="15" v-if="!isOneWay"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import rangeSlider from '@/sabre/common/atom/rangeSlider';
import filterControlMixin from '@/sabre/fo/searchResult/filterPanel/filterControlMixin';

const tMinutesPerDay = 24 * 60;

export default {
  mixins: [filterControlMixin],
  props: ['type'],
  components: { rangeSlider },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    isFiltered() {
      return this.filterApplied && (this.departureFilter[0] !== 0 || this.departureFilter[1] !== tMinutesPerDay || this.arrivalFilter[0] !== 0 || this.arrivalFilter[1] !== tMinutesPerDay);
    },
  },
  watch: {
    departureFilter() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'departureTimeFilter', value: this.departureFilter });
    },
    arrivalFilter() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'arrivalTimeFilter', value: this.arrivalFilter });
    },
    departureTimeFilter() {
      this.departureFilter = this.departureTimeFilter;
    },
    arrivalTimeFilter() {
      this.arrivalFilter = this.arrivalTimeFilter;
    },
  },
  data() {
    return {
      departureFilter: [0, tMinutesPerDay],
      arrivalFilter: [0, tMinutesPerDay],
      filterApplied: false,
    };
  },
  methods: {
    clean() {
      this.departureFilter = [0, tMinutesPerDay];
      this.arrivalFilter = [0, tMinutesPerDay];
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'departureTimeFilter', value: this.departureFilter });
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'arrivalTimeFilter', value: this.arrivalFilter });
    },
    save() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.timeFilter).hide();
      this.applyFilter();
      this.filterApplied = true;
    },
    showedDropdown() {
      this.filterApplied = false;
    },
  },
};
</script>
